import logo from "./assets/logo.jpg";
import logoPink from "./assets/inu.png";
import copy from "./assets/copy.svg";
import { useEffect, useState } from "react";
import tg from "./assets/telegram.png";
import tw from "./assets/twitter.png";
import { BlossomScene, Petal } from "./petal";

function App() {
  const address = "0x270719e21852e0e817c4663cc9f1567441d6eaac";
  const tgl = "";
  const twl = "";

  const [msg, setMsg] = useState("");

  const copyClick = async () => {
    try {
      await navigator.clipboard.writeText(address);
      setMsg("copied!");
      setTimeout(() => {
        setMsg("");
      }, 3000);
    } catch (e) {
      setMsg("error in copying, try manually");
      setTimeout(() => {
        setMsg("");
      }, 3000);
    }
  };

  useEffect(() => {
    const petalsTypes = [
      new Petal({ customClass: "petal-style1" }),
      new Petal({ customClass: "petal-style2" }),
      new Petal({ customClass: "petal-style3" }),
      new Petal({ customClass: "petal-style4" }),
    ];
    const myBlossomSceneConfig = {
      id: "blossom_container",
      petalsTypes,
    };
    const myBlossomScene = new BlossomScene(myBlossomSceneConfig);
  }, []);

  return (
    <div className="App bg-black text-gray-50">
      <div id="blossom_container" className="pointer-events-none z-10"></div>
      <div className="relative">
        <p className="absolute left-8 top-3 text-white text-3xl font-bold flex items-center">
          <img className="w-20" src={logoPink} alt="" />
          切腹
        </p>
        <div className="absolute top-8 right-8 flex justify-center items-center gap-4">
          <a href={tgl}>
            <img src={tg} alt="telegram" className="w-10" />
          </a>
          <a href={twl}>
            <img src={tw} alt="twitter" className="w-10" />
          </a>
        </div>
        {/* <img className="bottom-8 absolute right-8 w-20" src={logoPink} alt="" /> */}
        <div className="flex items-center py-10 max-w-screen-xl px-4 mx-auto flex-wrap md:flex-nowrap">
          <div className="p-8">
            <h1 className="text-6xl tracking-tight uppercase sm:text-6xl md:text-6xl lg:text-7xl xl:text-8xl samurai leading-10">
              <span className="block  text-inu-pink">Seppuku Inu</span>{" "}
              <span className="block text-4xl md:text-6xl">
                Sacrificing tokens with honour
              </span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-lg text-gray-100 sm:text-xl md:mt-5 md:max-w-3xl">
              Seppuku Inu (SEPPUKU) is an innovative deflationary token
              perfectly built for today’s DeFi market. Our DeFi Dynasty has been
              under attack from panic sellers and now we get revenge.
            </p>
          </div>
          <div className="-order-1 w-full md:w-5/12">
            <img src={logo} alt="" />
          </div>
        </div>
      </div>
      <section className="bg-inu-pink py-20 flex justify-center text-gray-900">
        <div>
          <h2 class="text-5xl sm:text-6xl tracking-tight samurai uppercase text-center">
            Contract address
          </h2>
          <div
            class="flex flex-col items-center px-4"
            style={{ maxWidth: "100vw" }}
          >
            <div class="inline-flex px-8 py-4 gap-4 bg-gray-900 mt-8 text-white items-center max-w-full">
              <p class="overflow-ellipsis flex-shrink overflow-hidden">
                {address}
              </p>
              <button class="flex-shrink-0 cursor-pointer" onClick={copyClick}>
                <img src={copy} alt="copy the address" />
              </button>
            </div>
            <p className="text-gray-900 pt-1">{msg}&nbsp;</p>
          </div>
          <div class="mt-8 text-white font-bold text-lg flex gap-8 justify-center items-center flex-wrap px-4">
            <a
              href="https://app.uniswap.org/#/swap?outputCurrency=0x270719e21852e0e817c4663cc9f1567441d6eaac&use=V2"
              class="p-4 shadow-md transition-all transform hover:shadow-lg hover:-translate-y-1 bg-gray-900"
              target="_blank"
              rel="noreferrer"
            >
              Buy on Uniswap
            </a>
            <a
              href="https://www.dextools.io/app/ether/pair-explorer/0x25a6b4a713c0bf2aed3d50cd566bf50434d1c29e"
              class="p-4 shadow-md transition-all transform hover:shadow-lg hover:-translate-y-1 bg-gray-900"
              target="_blank"
              rel="noreferrer"
            >
              Dextools chart
            </a>
          </div>
        </div>
      </section>
      <Content />
      <section className="bg-inu-pink py-20 flex justify-center text-gray-900">
        <div>
          <h1 className="text-6xl text-center samurai uppercase">Join us</h1>
          <div className="mt-10 flex justify-center items-center gap-8">
            <a href={tgl}>
              <img src={tg} alt="telegram" className="w-20" />
            </a>
            <a href={twl}>
              <img src={tw} alt="twitter" className="w-20" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

const Content = () => {
  useEffect(() => {
    const petalsTypes = [
      new Petal({ customClass: "petal-style1" }),
      new Petal({ customClass: "petal-style2" }),
      new Petal({ customClass: "petal-style3" }),
      new Petal({ customClass: "petal-style4" }),
    ];
    const myBlossomSceneConfig = {
      id: "cont_cont",
      petalsTypes,
    };
    const myBlossomScene = new BlossomScene(myBlossomSceneConfig);
  }, []);

  return (
    <div className="relative py-16 text-gray-50 overflow-hidden">
      <div
        id="cont_cont"
        className="pointer-events-none absolute w-full h-full"
      ></div>
      {/* <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-inu-pink"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-inu-pink"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-inu-pink"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div> */}
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-xl max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-inu-pink font-semibold tracking-wide uppercase">
              Seppuku Inu
            </span>
            <span className="mt-2 block text-center leading-10 samurai uppercase text-gray-50 text-6xl ">
              Sacrificing tokens with honour
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-50 leading-8">
            As a samurai practice, seppuku was used voluntarily by samurai to
            die with honour rather than fall into the hands of their enemies
            (and likely be tortured).
            <br />
            <br /> On the blockchain, every sell of SEPPUKU results in a bloody
            20% death to tokens sold, sending them to a burn wallet, gone
            forever.
            <br />
            <br /> This creates noble value for the remaining tokens and rewards
            the samurai’s who stand strong amidst challenges.
          </p>
        </div>
        <div className="mt-20 prose prose-inu-pink prose-xl text-gray-50 mx-auto">
          <h2 className="text-inu-pink text-6xl samurai uppercase font-normal">
            How it works
          </h2>
          <p>
            A contract with marketing allowance and a built in burn to honour
            those who die before us.
          </p>
          <ul>
            <li>
              <span className="font-extrabold">Safe & Secure</span> <br /> After
              Liquidity Locked, the lead Samuaris will add LP at upward levels
              to attract bigger buys and secure floors.
            </li>
            <li className="mt-8">
              <span className="font-extrabold">Operational Budget</span> <br />{" "}
              8% tax goes towards the team’s ability to lock in marketing,
              influencers, product development and more.
            </li>
            <li className="mt-8">
              <span className="font-extrabold">The Sacrifice</span> <br /> As
              the Seppuku tradition goes, those who are ‘on their way out’ will
              pay the ultimate price and die with honour. There will be an
              additional 20% tax, paid in token blood, that rewards the strong
              left behind.
            </li>
          </ul>
          <h2 className="text-inu-pink text-6xl samurai uppercase font-normal">
            Roadmap
          </h2>
          <div>
            <ul className="flex justify-between items-start gap-8 flex-wrap">
              <li>
                <span className="font-extrabold">Phase 1</span> <br />
                Ninja like Stealth Launch <br />
                Social Media <br />
                Develop Website <br />
                Token Marketing <br />
                Community Leaders <br />
                Burn Supply <br />
              </li>
              <li>
                <span className="font-extrabold">Phase 2</span> <br /> Social
                Media Ads <br />
                NFT Artist Contest <br />
                CMC/CG <br />
                Influencer Support <br />
                Burn Supply <br />
              </li>
              <li>
                <span className="font-extrabold">Phase 3</span> <br />
                NFT Mint <br />
                Exchanges
                <br />
                Mass Marketing
                <br />
                Burn Supply
                <br />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
